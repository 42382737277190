.investment {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Roboto;

  header {
    display: flex;
    justify-content: space-between;
    margin: 45px 60px 0 43px;


    @media #{$smWidth $xsWidth} {
      margin: 30px 24px;

      img{
        width: 136px;
        height: 32px;
        object-fit: contain;
      }
    }

    img {
      width: 170px;
      height: 40px;

      @media #{$smWidth $xsWidth} {
          width: 136px;
      }

    }

    .wallet {
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      height: 32px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      &__balance {
        padding-right: 16px;
        border-right: 1px #ffffff solid;
        font-size: 13px;
      }

      &__address {
        padding-left: 16px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          opacity: 0.9;
        }

        @media #{$smWidth $xsWidth} {
          p {
            display: none;
          }
        }

        .dot {
          width: 12px;
          height: 12px;
          background: linear-gradient(135deg, #FFFFFF 4.17%, rgba(255, 255, 255, 0) 75%);
          border: 0.6px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 6px;
          margin-right: 6px;
        }

        svg {
          width: 16px;
          margin-left: 6px;
          cursor: pointer;

          @media #{$smWidth $xsWidth} {
           display: none;
          }

          &:hover {
            g {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__init {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    @media #{$smWidth $xsWidth} {
      padding-left: 0;
      flex: 1;
    }

    &__frame {
      display: flex;
      margin: auto;
      @media #{$smWidth $xsWidth} {
        justify-content: center;
        word-break: break-all;
        text-align: center;
        align-items: center;
      }
    }

    &__title {
      font-family: Futura PT;
      font-style: normal;
      font-weight: bold;
      font-size: 49px;
      @media #{$smWidth $xsWidth} {
        font-size: 36px;
      }
    }

    &__sub_title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      margin-top: 18px;
      margin-bottom: 40px;
      width: 578px;
      opacity: 0.6;
      @media #{$smWidth $xsWidth} {
        width: fit-content;
        font-size: 16px;
        line-height: 132%;
      }
    }
  }

  button {
    width: 240px;
    height: 60px;
    border: 1px solid #FFFFFF;
    ont-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    outline: none;
    border-radius: 100px;
    color: #ffffff;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$smWidth $xsWidth} {
      width: 100%;
    }

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.6);
    }

    &:disabled {
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.3);
    }

    img {
      height: 20px;
      margin-right: 20px;
    }
  }

  &__contribution {
    margin: auto;
    color: #ffffff;

    &__balls {
      display: flex;
      justify-content: center;
      margin: -145px;
      & > * {
        margin: 145px;
      }

      @media #{$smWidth $xsWidth} {
        margin: -27px;
        & > * {
          margin: 27px;
        }
      }

      &__ball {
        width: 186px;
        height: 186px;
        font-size: 16px;
        background: linear-gradient(180deg, #000000 43.44%, #FFFFFF 207.92%);
        border-radius: 93px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 12px;
        font-family: Roboto-Regular;
        line-height: 28px;

        @media #{$smWidth $xsWidth} {
          width: 142px;
          height: 142px;
          text-align: center;
        }
      }

    }

    &__table {
      background: #000000;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 32px;
      width: 800px;
      margin-top: 47px;

      @media #{$smWidth $xsWidth} {
        width: 100%
      }

      &__title {
        font-family: Futura PT;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        @media #{$smWidth $xsWidth} {
          font-size: 24px;
        }
      }

      ul {
        @media #{$smWidth $xsWidth} {
          width: 100vw
        }
        li {
          display: flex;
          height: 60px;
          justify-content: space-between;
          align-items: center;
          padding: 0 52px;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          word-break: break-all;
          font-family: Roboto-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 148.69%;
          @media #{$smWidth $xsWidth} {
            flex-direction: column;
            text-align: left;
            align-items: flex-start;
            justify-content: center;
            padding: 18px 24px;
            height: fit-content;
            margin: -8px;
            & > * {
              margin: 8px;
            }
          }

          p:first-child {
            color: rgba(255, 255, 255, 0.5);
            font-family: Roboto;
          }

          p {
            display: flex;
            align-items: center;

            button {
              height: 40px;
              width: 150px;
              margin-left: 24px;
            }
          }
        }
      }
    }


  }

  &__modal {
    width: 655px;
    padding-top: 52px;
    background: linear-gradient(283.31deg, rgba(255, 255, 255, 0.18) -2.53%, rgba(255, 255, 255, 0.17) 18.66%, rgba(255, 255, 255, 0) 98.68%);
    border-radius: 42px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    position: relative;
    @media #{$smWidth $xsWidth} {
      width: 100%;
      height: 100%;
      flex: 1;
      padding: 48px 24px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      justify-content: center;
      font-size: 24px;
    }

    p {
      max-width: 405px;
      text-align: center;
      line-height: 148.69%;
    }

    &__icon {
      width: 45px;
      height: 45px;
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__loading {
      width: 60px;
      height: 60px;
      -webkit-animation: loading 3s linear infinite;
    }
  }
}

.btn_group {


  button {
    margin-top: 50px;
    @media #{$smWidth $xsWidth} {
      margin-top: 0;
    }
  }

  @media #{$smWidth $xsWidth} {
    flex-direction: column;
    margin: -12px;
    & > * {
      margin: 12px;
    }
  }
}

.modal-wallets {
  @media #{$smWidth $xsWidth} {
    justify-content: flex-start;
  }

  button:hover {
    opacity: 0.6;
  }
}

.modal-profile {

}


.connecting {
  @media #{$smWidth $xsWidth} {
    justify-content: center;
  }
}

.connected {
  @media #{$smWidth $xsWidth} {
    justify-content: center;
    padding: 0 60px;
  }

  button {
    margin-top: 50px;
    @media #{$smWidth $xsWidth} {
      margin: auto;
      margin-bottom: 48px;
    }
  }


}

.modal_bottom {
  @media #{$smWidth $xsWidth} {
    position: absolute;
    top: auto;
    bottom: 48px;
    width: inherit;
    padding: 0 60px;
  }
}

.init_layout {
  padding-left: 125px;
  p{
    margin: 0;
  }

  p:nth-child(2){
    margin-top: 18px;
  }

  button {
    margin-top: 36px;
  }

  @media #{$smWidth $xsWidth} {
    padding-left: 0;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px;
    flex: 1;

    p{
      width: 100%;
      margin: 0;
    }

    p:first-child{
      margin-top: 36px;
      width: 100%;
    }

    p:nth-child(2){
      margin-top: 18px;
    }

    button{
      margin-top: 36px;
    }

  }
}

.init_frame {
  @media #{$smWidth $xsWidth} {
    flex: 0;
  }
}