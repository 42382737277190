//.exhibition-hall {
//
//    text-align: center;
//    display: flex;
//    flex-direction: column;
//    justify-content: space-between;
//    align-items: center;
//    flex-grow: 1;
//
//    &__body {
//
//        padding: 70px 0;
//        flex-shrink: 0;
//        width: 100%;
//
//        @media ($smWidth) {
//
//            padding: 0 0 48px;
//
//        }
//
//
//        .h1 {
//
//            margin: 48px auto 10px;
//            max-width: 100%;
//            width: 768px;
//
//            @media ($desktop) {
//
//                line-height: 72px;
//
//            }
//
//        }
//
//        ol {
//
//            display: flex;
//            margin: 10px -18px;
//            color: var(--text);
//            justify-content: center;
//            counter-reset: headings 0;
//
//            @media ($mdWidth) {
//
//                flex-wrap: wrap;
//
//            }
//
//            li {
//
//                width: 336px;
//                margin: 18px;
//                counter-increment: headings 1;
//
//                &::before {
//
//                    content: counter(headings, decimal);
//                    font-size: 20px;
//                    display: block;
//                    margin: 20px;
//                    color: var(--yellow);
//
//                }
//
//            }
//
//        }
//
//    }
//
//    &__btn {
//
//        display: flex;
//        margin-top: 42px;
//        justify-content: center;
//
//        a {
//
//            color: var(--yellow);
//            line-height: 40px;
//            border-radius: 22px;
//            border: 1px solid;
//            padding: 0 23px;
//            transition: var(--transitionDefault);
//
//            @media ($desktop) {
//
//                &:hover {
//
//                    color: var(--bg);
//                    background-color: var(--yellow);
//
//                }
//
//            }
//
//
//        }
//
//    }
//
//    &__img {
//
//        flex-shrink: 0;
//        display: flex;
//        mix-blend-mode: lighten;
//        justify-content: center;
//
//        @media ($smWidth) {
//
//            img {
//
//                max-width: 100%;
//                height: auto;
//
//            }
//
//        }
//
//    }
//
//}


.exhibition-hall {
    &-head {
        margin: 120px 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 540px) {
            display: block;
        }

        &__title {
            @media (max-width: 540px) {
                margin-bottom: 16px;
            }
        }

        &__btn-wrapper {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            padding: 26px 72px;

            @media (max-width: 540px) {
                border-left: none;
                padding: 0;
            }
        }
    }

    &-slider {
        margin: 50px 0;

        .slick-arrow {
            svg {
                fill: none;
                stroke: var(--yellow);
            }

            &.slick-disabled {
                cursor: default;

                path {
                    stroke: rgba(#fff, 0.2);
                }
            }
        }

        .slick-prev {
            left: -36px;

            @media (width: 768px) {
                left: -22px;
            }

            @media #{$smWidth} {
                left: 0;
            }
        }

        .slick-next {
            right: -36px;

            @media (width: 768px) {
                right: -22px;
            }

            @media #{$smWidth} {
                right: 0;
            }
        }

        .slick-prev,
        .slick-next {
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            display: block;
            padding: 0;
            transform: translate(0, -50%);
            cursor: pointer;
            color: transparent;
            border: none;
            outline: none;
            background: transparent;
            z-index: 100;
        }

        .slick-slide {
            border-left: 1px solid rgba(255, 255, 255, 0.1);

            &:first-child {
                border-left: none;
            }
        }

        .slick-current {
            border-left: none;
        }

        &__item {
            display: block !important;
            text-align: center;
            color: #fff;
            max-width: 326px;
            margin: 0 auto;

            @media (max-width: 410px) {
                max-width: 240px;
            }

            .item {
                &__title {
                    font-weight: 400;
                    margin-bottom: 4px;

                    @media (max-width: 410px) {
                        font-size: 18px;
                    }
                }

                &__date {
                    font-size: 12px;
                    opacity: 0.3;
                    margin-bottom: 4px;
                }

                &__subtitle {
                    opacity: 0.8;

                    @media (max-width: 410px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 32px -18px 82px;

        @media #{$smWidth} {
            display: block;
            margin: 0;
        }

        &__item {
            background-color: #fff;
            box-shadow: 0px 4px 34px rgba(31, 31, 31, 0.13);
            border-radius: 10px;
            width: 348px;
            margin: 8px;
            color: var(--bg);
            text-align: center;
            overflow: hidden;

            @media #{$smWidth} {
                max-width: 100%;
                margin: 18px auto;
            }

            .item {
                &__image {
                    img {
                        width: 100%;

                        @media #{$smWidth} {
                            height: 100%;
                        }
                    }
                }

                &__content {
                    padding: 24px 16px;
                }

                &__title {
                    display: block;
                    margin-bottom: 4px;
                    color: var(--bg);

                    &:hover {
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                &__author {
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 16px;
                }

                &__hashtags {
                    max-width: 270px;
                    margin: 0 auto 16px;

                    &>span {
                        cursor: pointer;
                    }
                }

                &__date {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }

    &-card {
        max-width: 704px;
        width: 100%;
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
        color: var(--bg);
        margin: 0 auto 121px;

        &__header {
            text-align: center;
            margin-bottom: 40px;

            &__author {
                font-size: 32px;
                font-weight: 400;
                margin-top: 14px;

                @media #{$smWidth} {
                    font-size: 18px;
                }
            }
        }

        &__content {
            padding: 40px 60px 60px;

            @media #{$smWidth} {
                padding: 20px 30px 30px;
            }
        }

        &__image {
            img {
                width: 100%;
                height: 100%;
            }
        }

        &__hashtags {
            margin-bottom: 32px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &>a {
                color: var(--bg);
                font-size: 19px;
                font-weight: bold;
                margin: 0 8px;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__table {
            margin: 0 -8px;

            tr {
                @media (max-width: 600px) {
                    display: block;
                    margin-bottom: 12px;
                }
            }

            th,
            td {
                padding: 8px;

                @media (max-width: 600px) {
                    display: block;
                    padding: 0;
                }
            }

            th {
                width: 180px;
                text-align: left;
                opacity: 0.5;
                font-weight: 400;
            }

            td {
                &.break-all {
                    word-break: break-all;
                }

                &>a {
                    color: var(--bg);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }
}
