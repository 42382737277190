* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;

    &.scroll-behavior-off {
        scroll-behavior: auto;
    }
}


#root {
    height: 100%;
    overflow-y: auto;
}

body {
    background: #000;
    color: #ffffff;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}


a {
    color: #ffffff;
    text-decoration: underline;
    transition: var(--transitionDefault);

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

::selection {
    color: #000000;
    background-color: #ffffff;
}

img {
    border: 0;
    display: block;
    position: relative;
}

li {
    list-style: none;
    position: relative;
}

svg {
    display: block;
    fill: var(--color);
    position: relative;
    transition: var(--transitionDefault);
}

table {
    border: 0;
    border-collapse: collapse;
}

hr {
    height: 1px;
    border: 0;
    background-color: #fff;
    opacity: 0.1;
}

@font-face {
    font-family: 'Jost';
    src: url('../../assets/fonts/Jost-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-bold';
    src: url('../../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../assets/fonts/FuturaPT-Demi.ttf');
}

.align-center {
    text-align: center;
}

.button-row {
    width: 100%;
    display: flex;
    gap: 12px;
}

/*
-----------------------------------------------------------------------------*/
.notsel {
    user-select: none;
}

.h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 400;

    @media #{$mdWidth} {
        font-size: 36px;
        line-height: 48px;
    }

    @media #{$smWidth} {
        font-size: 24px;
        line-height: 36px;
    }
}

.h2 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
}

.h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
}

.status {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #22292f;
    margin-top: 8px !important;
}

.h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

main,
header,
footer,
section,
article,
aside,
time,
nav {
    display: block;
    position: relative;
}

.wrapper,
.main {
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
    display: none !important;
}

.visuallyhidden {
    position: absolute !important;

    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;

    clip: rect(0 0 0 0) !important;
    overflow: hidden !important;
}

a[href^='tel'],
.white-space-nowrap {
    white-space: nowrap;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-40 {
    opacity: 0.4;
}

.fz-14 {
    font-size: 14px;
}

.fz-20 {
    font-size: 20px;
}

.color-gray {
    opacity: 0.2;
}

.value {
    font-size: 18px;
    font-family: HelveticaNeue-Bold, HelveticaNeue;
    font-weight: bold;
    color: #22292f;
}

.withdraw-title {
    font-size: 12px;
    font-family: HelveticaNeue;
    color: #7a7f82;
}

.gold-color {
    color: var(--yellow);
}

.language {
    border-radius: 5px;
    font-size: 14px;
    font-family: HelveticaNeue;
    color: #22292f;
    padding: 10px;
    cursor: pointer;
    z-index: 999;
    display: flex;
    align-items: center;
    position: relative;

    img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .language-items {
        display: none;
        flex-direction: column;
        background: #f2f0eb;
        box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
        border-radius: 5px 5px 0px 0px;
        padding: 12px 10px;
        right: 50%;
        position: absolute;
        bottom: 80%;
        transform: translate(50%);
        p {
            color: #22292f;
            width: 90px;
            padding: 5px 0;
            text-align: center;
            &:hover {
                background-color: rgba(212, 233, 226, 1);
                border-radius: 5px;
            }
        }
    }

    &:hover {
        display: flex;
        .language-items {
            display: flex;
        }
    }
}

.satellite {
    position: fixed;
    width: 252px;
    bottom: 0;
    right: 0;
}
@media #{$smWidth} {
    .satellite {
        position: absolute;
        width: 252px;
        bottom: 0;
        right: 50%;
        transform: translate(50%);
    }
    .language {
        background: transparent;
        border-radius: 5px;
        font-size: 14px;
        font-family: HelveticaNeue;
        color: #22292f;
        padding: 10px;
        cursor: pointer;
        z-index: 999;
        display: flex;
        align-items: center;
        position: relative;
        img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .language-items {
            display: none;
            flex-direction: column;
            background: #f2f0eb;
            box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
            border-radius: 5px 5px 0px 0px;
            bottom: 40px;
            position: absolute;
            padding: 12px 10px;
            right: 50%;
            transform: translateX(50%);
            p {
                color: #22292f;
                width: 90px;
                padding: 5px 0;
                text-align: center;
                &:hover {
                    background-color: rgba(212, 233, 226, 1);
                    border-radius: 5px;
                }
            }
        }

        &:hover {
            display: flex;
            .language-items {
                display: flex;
            }
        }
    }
}
.right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

canvas{
    width:100%;
    height:100vh;
}


.default_modal {
    width: 480px;
    padding-top: 24px;
    background: linear-gradient(283.31deg, rgba(255, 255, 255, 0.18) -2.53%, rgba(255, 255, 255, 0.17) 18.66%, rgba(255, 255, 255, 0) 98.68%);
    border-radius: 42px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    position: relative;

    button {
        width: 240px;
        height: 48px;
        border: 1px solid #FFFFFF;
        ont-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        border-radius: 100px;
        color: #ffffff;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$smWidth $xsWidth} {
            width: 100%;
        }

        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.6);
        }

        &:disabled {
            border: 1px solid rgba(255, 255, 255, 0.3);
            color: rgba(255, 255, 255, 0.3);
        }

        img {
            height: 20px;
            margin-right: 20px;
        }

    }


    @media #{$smWidth $xsWidth} {
        width: 100%;
        height: 100%;
        flex: 1;
        padding: 48px 24px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        justify-content: center;
        font-size: 24px;
    }

    p {
        max-width: 405px;
        text-align: center;
        line-height: 148.69%;
        font-size: 13px;
    }

    &__icon {
        width: 45px;
        height: 45px;
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 70px 0 32px;
        align-items: center;
        display: flex;
        margin-bottom: 14px;

        &__dropdown {
            height: 32px;
            width: 126px;
        }
    }

    &__title {
        font-family: Futura PT;
        margin-bottom: 28px;
        font-size: 18px!important;

        @media #{$smWidth $xsWidth} {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 24px!important;
            line-height: 124%;
        }
    }

    &__loading {
        width: 60px;
        height: 60px;
        -webkit-animation: loading 3s linear infinite;
    }
}

.modal-wallets {
    @media #{$smWidth $xsWidth} {
        justify-content: flex-start;
        padding: 48px 0;
    }

    button {
        width: -webkit-fill-available;
        margin: 0 24px;
    }

    button:hover {
        opacity: 0.6;
    }
}

.modal-switch {
    @media #{$smWidth $xsWidth} {
        width: fit-content;
        justify-content: flex-start;
        padding: 24px 24px 81px 24px;
        flex: 0;
        margin-bottom: 0;

        p{
            font-size: 14px;
        }
    }

    a {
        color: #ffffff;
        text-decoration: underline;
        &:hover {
            opacity: 0.6;
        }
    }


}

.modal_profile {

    .btn_group {

    }

    @media #{$smWidth $xsWidth} {
        justify-content: flex-start;
        padding: 52px 0;
        .btn_group {
           margin-top: auto!important;
            width: -webkit-fill-available;
            margin: 0 60px;
        }
    }


    &__address {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 148.69%;
        display: flex;
        align-items: center;
        margin-left: -14px;
        margin-right: -14px;
        & > * {
            margin-left: 14px;
            margin-right: 14px;
        }

        p{
            font-size: 24px;
        }

        .dot {
            width: 28px;
            height: 28px;
            background: linear-gradient(135deg, #FFFFFF 4.17%, rgba(255, 255, 255, 0) 75%);
            border: 0.6px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 14px;
            margin-right: 6px;
        }

        @media #{$smWidth $xsWidth} {
           margin-top: 100px;
        }
    }

    &__copy{
        font-family: IBM Plex Mono;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        margin-left: -6px;
        margin-right: -6px;
        margin-top: 12px;

        & > * {
            margin-left: 6px;
            margin-right: 6px;
        }
        svg {
            width: 16px;
            cursor: pointer;

            &:hover {
                g {
                    opacity: 1;
                }
            }
        }
    }

}

.close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    left: auto;
    cursor: pointer;
}

.chain_tip {
    display: flex;
    justify-content: space-between;
    width: 416px;
    font-size: 13px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 148.69%;
    color: #ffffff;
    p:nth-child(1){
        color: rgba(255, 255, 255, 0.6);
    }

    @media #{$smWidth $xsWidth} {
        width: 100%;
    }
}

.line {
    width: 416px;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 18px auto 24px auto;

    @media #{$smWidth $xsWidth} {
        width: 100%;
    }
}

.btn_group {
    display: flex;
    width: 442px;
    margin-left: -12px;
    margin-right: -12px;
    & > * {
        margin-left: 12px;
        margin-right: 12px;
    }

    @media #{$smWidth $xsWidth} {
        width: inherit;
    }

    button {
        margin-top: 50px;
        display: block;
        @media #{$smWidth $xsWidth} {
            margin-top: 0;
        }
    }

    @media #{$smWidth $xsWidth} {
        flex-direction: column;
        margin: -12px;
        & > * {
            margin: 12px;
        }
    }
}

.confirm_modal {

    &__loading {
        width: 60px;
        height: 60px;
        -webkit-animation: loading 3s linear infinite;
        margin-bottom: 10px;
    }

    p {
        margin-top: 10px
    }
}

.transactions {
    width: 418px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 22px;
    margin-top: 36px;
    padding: 16px 24px;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    max-height: 240px;
    overflow: auto;
    position: relative;

    .clear {
        position: absolute;
        top: 16px;
        right: 24px;
        font-size: 14px;
        opacity: 0.8;
        text-decoration: none;

        &:hover {
            opacity: 1;
            text-decoration: underline;
        }
    }


    ul{
        padding-top: 8px;
        li {
            a {
                color: #ffffff;
                font-size: 14px;
                text-decoration: underline;
                &:hover {
                    opacity: 0.6;
                }
            }

            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
        }
    }

}

button {
    width: 100%;
    height: 48px;
    display: flex;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background-color: #000;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    border-radius: 100px;
    line-height: 48px;
    justify-content: center;
    outline: none;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
    padding: 0 24px;

    img {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        margin-bottom: 0;
    }

    &:hover {
        opacity: 0.6;
    }

    &:disabled {
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: rgba(255, 255, 255, 0.3);
        opacity: 1;
    }
}

.small {
    height: 32px;
    padding: 0 19px;
    width: fit-content;
}

.input_error {
    border: 1px solid rgba(255, 0, 0, 0.6)!important;
}

.fetch__loading {
    width: 32px;
    height: 32px;
    margin: 45px auto;
    -webkit-animation: loading 3s linear infinite;
}


.token__frame {
    width: 100%;
    height: 48px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    p {
        color: #ffffff;
    }

    &__extra {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-top: -2px;
        margin-bottom: -2px;
        & > * {
            margin-top: 2px;
            margin-bottom: 2px;
        }

        p:nth-child(2) {
            font-size: 12px;
            color: rgba(255,255,255,0.6);
        }
    }

    &:hover {
        opacity: 0.6;
        cursor: pointer;
    }
}
