.home {

  &__top {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #000000;

    .top_content {
      display: flex;
      align-items: center;
    }

    .content_main{
      margin-left: 11px;
      margin-right: 2px;
    }

    .content_address{
      margin-right: 42px;
      @media #{$smWidth $xsWidth} {
       font-size: 12px;
        text-align: center;
      }
    }

    img{
      width: 32.51px;
      height: 32px;
    }

    p{
      font-family: Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    a {
      width: 140px;
      height: 32px;
      border: 1px solid #FFFFFF;
      ont-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      outline: none;
      border-radius: 100px;
      color: #ffffff;
      background-color: #000000;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }

      @media #{$smWidth $xsWidth} {
        margin: auto;
      }
    }

    @media #{$smWidth $xsWidth} {
      flex-direction: column;
      height: 100px;
      padding: 20px 0;
      font-size: 14px;
      width: 100%;
    }
  }

  &__frame {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-repeat: no-repeat;
    background-size: 100%;

    .bridge_btn {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 24px;
      a{
        background-color: rgba(255, 255, 255, 0.8);
        background-color: #000000;
        border: none;
      }
    }

    h2 {
      font-family: Jost;
      font-style: normal;
      font-weight: 500;
      font-size: 50px;
      margin-top: 115px;
      width: 100%;
      text-align: center;
      color: #000000;
    }

    &_blocks{
      width: 1200px;
      margin: auto;
      height: 862px;
      display: flex;
      position: relative;
      color: #000000;

      h2 {
        font-family: Jost;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        position: absolute;
        margin: auto;
        top: 421px;
        width: 100%;
        text-align: center;
      }
    }

    &__block{
      text-align: center;
      border-radius: 1000px;
      width: 237px;
      height: 237px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000000;
      display: inline-flex;

      img{
        margin-bottom: 11px;
      }

      h5{
        font-family: Roboto-bold;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 123.19%;
      }

      p{
        font-family: Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 134.69%;
      }


    }

    &__teams {
      display: flex;
      justify-content: center;
      gap: 205px;
      width: fit-content;
      margin: auto;
      position: relative;

      .bg {
        position: absolute;
        z-index: 9;
        display: flex;
        width: 100%;
        top: 35px;

        img {
          transform:rotate(-5.6deg);
        }
      }

      &__team{
        border-radius: 1000px;
        width: 401px;
        height: 401px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img{
          width: 60px;
          position: relative;
        }

        h3{
          font-family: Roboto-bold;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 134%;
          margin-top: 12px;
        }

        p{
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 124%;
          color: rgba(255, 255, 255, 0.6);
        }


      }
    }

    @media #{$smWidth $xsWidth} {
      height: fit-content;
    }

    header{
      display: flex;
      justify-content: space-between;
      margin: 45px 60px 0 43px;

      @media #{$smWidth $xsWidth} {
        margin: 34px 0 0 26px;
      }

      img{
        width: 170px;
      }

      a {
        font-family: Jost;
        height: 36px;
        line-height: 36px;
        border-radius: 100px;
        padding: 0 18px;
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.1);

        @media #{$smWidth $xsWidth} {
          display: none;
        }
      }
    }

    &__page {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 48px;
      margin: auto;
      width: 1200px;



      @media #{$smWidth $xsWidth} {
        flex: none;
        padding-left: 0;
        flex-direction: column-reverse;
        gap: 0;
        width: 100%;
        margin-top: 24px;

        .circle {
          width: 100%;
        }

        video{
          display: none;
          width: 100%;
        }
      }

      &__content {

        @media #{$smWidth $xsWidth} {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__title {
          font-family: Jost;
          font-style: normal;
          font-weight: bold;
          font-size: 46px;
          max-width: 642px;
          z-index: 9;
          width: 642px;

          @media #{$smWidth $xsWidth} {
            font-size: 23px;
            width: 100vw;
            padding: 0 24px;
            text-align: center;
            line-height: 132%;
          }
        }

        a{
          font-family: Jost;
          height: 44px;
          font-size: 18px;
          line-height: 44px;
          border-radius: 100px;
          color: #ffffff;
          text-align: center;
          background: rgba(255, 255, 255, 0.1);
          margin-top: 44px;
          margin-bottom: 46px;
          width: 260px;
          @media (min-width: 748px) {
            display: none;
          }
        }

        .coming{
          @media #{$smWidth $xsWidth} {
            margin-top: 0!important;
          }
        }

        &__sub_title{
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 36px;
          margin-top: 18px;
          margin-bottom: 28px;
          opacity: 0.6;
          z-index: 9;
          width: 686px;

          @media #{$smWidth $xsWidth} {
            font-size: 16px;
            width: 100vw;
            padding: 0 24px;
            text-align: center;
            line-height: 132%;
          }
        }

        &__calc {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          width: fit-content;
          border: 1.4px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          padding: 16px 30px;
          z-index: 9;

          @media #{$smWidth $xsWidth} {
            width: 240px;
            margin: auto;
            font-size: 14px;
            line-height: 36px;
            height: 36px;
            padding: 0 30px;
          }
        }

        &__summary {
          width: 240px!important;
          height: 60px!important;
          background: #FFFFFF!important;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 100px;
          display: flex!important;
          color: #000!important;
          align-items: center;
          justify-content: center;
          font-family: Roboto-Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          margin-top: 52px;

          &:hover {
            background: linear-gradient(279.01deg, #FFFFFF 36.98%, rgba(255, 255, 255, 0) 104.56%)!important;
            border-color: #000;
          }

          @media #{$smWidth $xsWidth} {
           display: none!important;
          }
        }
      }


    }

    &__bottom {
      padding-top: 170px;
      font-family: Jost;
      font-style: normal;
      font-weight: 500;
      font-size: 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$smWidth $xsWidth} {
        font-size: 24px;
        line-height: 133.5%;
        padding-top: 43px;
      }

      &__backed {
        display: flex;
        gap: 122px;
        margin-top: 106px;

        @media #{$smWidth $xsWidth} {
          flex-direction: column;
          gap: 0;
          margin-top: 0;
          img {
            width: 108px;
            margin-top: 53px;
          }
        }
      }

      &__divider{
        width: 1px;
        height: 46px;
        background-color: rgba(255, 255, 255, 0.2);

        @media #{$smWidth $xsWidth} {
          display: none;
        }
      }

      &__footer{
        width: -webkit-fill-available;
        height: 159px;
        margin-top: 251px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 62px 70px;
        position: relative;

        p{
          position: absolute;
          left: 70px;
          top: 110px;
          font-size: 15px;
        }

        @media #{$smWidth $xsWidth} {
          margin-top: 60px;
          height: 107px;

          img{
            display: none;
          }

          ul{
            width: 100vw;
            gap: 48px;
            display: flex;
          }
        }

        ul{
          display: flex;
          gap: 48px;


          li{
            @media #{$smWidth $xsWidth} {
              flex: 1;
              display: flex;
              justify-content: center;
            }
            a {
              svg {
                fill: #7a7f82;
              }
              &:hover svg {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }

  }
}

.block1 {
  background: linear-gradient(213.92deg, #FFFFFF 23.05%, rgba(255, 255, 255, 0) 86.8%);
  margin-top: 364px;
  margin-bottom: 257px;
}

.block2 {
  background: linear-gradient(251.9deg, #FFFFFF 18.8%, rgba(255, 255, 255, 0) 90.96%);
  margin-top: 156px;
  margin-bottom: 465px;

}

.block3 {
  background: linear-gradient(105.22deg, #FFFFFF 8.89%, rgba(255, 255, 255, 0) 93.32%);
  margin-top: 545px;
  margin-bottom: 76px;
  margin-left: -30px;
}

.block4 {
  background: linear-gradient(279.55deg, #FFFFFF 12.34%, rgba(255, 255, 255, 0) 94.05%);
  margin-top: 122px;
  margin-bottom: 499px;
  margin-left: -20px;
}

.block5 {
  background: linear-gradient(57.31deg, #FFFFFF 10.78%, rgba(255, 255, 255, 0) 83.71%);
  margin-top: 502px;
  margin-bottom: 119px;
}

.block6 {
  background: linear-gradient(17.22deg, #FFFFFF 5.24%, rgba(255, 255, 255, 0) 90.68%);
  margin-top: 203px;
  margin-bottom: 418px;
  margin-left: -136px;
}

.team1{
  background: linear-gradient(180deg, #000000 43.44%, #000000 136.15%);
  margin-top: 46px;
}

.team2{
  background: linear-gradient(180deg, #232121 43.44%, rgba(35, 33, 33, 0) 136.15%);
  margin-top: 128px;
  margin-bottom: 103px;
}
