.popup_column {
  position: fixed;
  top: 140px;
  right: 4rem;
  max-width: 355px !important;
  z-index: 3;
}

.mobile {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Futura PT;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 148.69%;
  letter-spacing: 0.01em;
  padding: 40px;
  text-align: center;

  @media  screen and (min-width: 420px) {
    display: none;
  }

}

.page {
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: none;

  @media screen and  (min-width: 420px) {
    display: flex;
  }

  @media #{$smWidth $xsWidth} {
    width: 136px;
  }

  header {
    display: flex;
    justify-content: space-between;
    margin: 45px 60px 0 43px;

    .chain_info {
      display: flex;
      align-items: center;
    }

    .connected_chain {
      width: 66px;
      height: 32px;
      margin-right: 12px;
      margin-left: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 4px;


      img {
        width: 16px;
      }

      p {
        font-family: Roboto-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        margin-left: 4px;
      }
    }


    @media #{$smWidth $xsWidth} {
      margin: 30px 24px;

      img {
        width: 136px;
        height: 32px;
        object-fit: contain;
      }
    }

    img {
      width: 170px;
      height: 40px;

      @media #{$smWidth $xsWidth} {
        width: 136px;
      }

    }

    .wallet {
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      height: 32px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      @media #{$smWidth $xsWidth} {
        width: 104px;
        padding: 0 8px;
      }


      &__balance {
        padding-right: 16px;
        border-right: 1px #ffffff solid;
        font-size: 13px;
        @media #{$smWidth $xsWidth} {
          border: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 0;
        }
      }

      &__address {
        padding-left: 16px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          opacity: 0.9;
        }

        @media #{$smWidth $xsWidth} {
          padding-left: 8px;
          p {
            display: none;
          }
        }

        .dot {
          width: 12px;
          height: 12px;
          background: linear-gradient(135deg, #FFFFFF 4.17%, rgba(255, 255, 255, 0) 75%);
          border: 0.6px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 6px;
          margin-right: 6px;
          @media #{$smWidth $xsWidth} {
            margin-right: 0;
          }
        }

        svg {
          width: 16px;
          margin-left: 6px;
          cursor: pointer;

          @media #{$smWidth $xsWidth} {
            display: none;
          }

          &:hover {
            g {
              opacity: 1;
            }
          }
        }
      }
    }

    .buttons {
      & > * {
        margin-left: -8px;
        margin-right: -8px;

      }
      button {
        width: fit-content;
        height: 32px;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

}

.bridge {
  width: 480px;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 32px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 32px 28px 32px;
  position: relative;

  button {
    margin-top: 18px !important;
    @media #{$mdHeight} {
      margin-top: 33px !important;
      height: 60px;
      font-size: 18px;
    }
  }

  @media #{$smWidth $xsWidth} {
    flex: 1;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media #{$mdHeight} {
    margin-top: 64px;
  }

  .inputs {
    position: relative;
    margin: -20px;
    @media #{$smWidth $xsWidth} {
      margin: -24px 0;
      width: 100%;
    }

    & > * {
      margin: 20px;
      @media #{$smWidth $xsWidth} {
        margin: 24px 0;
      }
    }
  }

  &__claim_text {
    color: #ffffff;
    text-decoration: underline;
    margin-top: 24px;
  }

  &__title {
    font-family: Futura PT;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 18px;
    line-height: 148.69%;
    letter-spacing: 0.01em;

    @media #{$smWidth $xsWidth} {
      font-size: 26px;
      margin-top: 0px;
    }
  }

  &__dropdown_frame {
    display: flex;

    img {
      padding: 0 20px;
      padding-top: 24px;
    }

    @media #{$smWidth $xsWidth} {
      flex-direction: column;

      img {
        width: 24px;
        height: 24px;
        padding: unset;
        margin: 20px auto auto;
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Safari and Chrome */
        transform: rotate(90deg);
      }
    }


    .dropdown {
      width: 178px;
      height: 80px;

      .default_drop {
        width: 176px;
        height: 46px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        box-sizing: border-box;
        border-radius: 14px;
        display: flex;
        align-items: center;

        @media #{$smWidth $xsWidth} {
          width: auto;
        }


        img{
          width: 18px;
          margin-left: 20px;
          padding: 0;

          @media #{$smWidth $xsWidth} {
            margin-top: auto;
            margin-right: 0;
          }
        }

        p{
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          margin-left: 20px;
        }
      }

      span {
        font-family: Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 148.69%;
        color: rgba(255, 255, 255, 0.6)
      }

      div {
        height: 8px;
      }

      @media #{$smWidth $xsWidth} {
        width: 100%;
      }
    }

    .dropdown:hover {
      z-index: 9;

    }

  }

  &__input_frame {
    display: flex;
    flex-direction: column;
    margin-top: -4px !important;

    & > * {
      margin-top: 4px;
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: 148.69%;
      white-space: nowrap;
    }

    input {
      width: -webkit-fill-available;
      height: 100%;
      outline: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: transparent;
      padding: 20px;
      font-size: 16px;
      font-family: Roboto-Regular;


      @media #{$smWidth $xsWidth} {
        padding-right: 20px;
      }
    }

    .error {
      color: rgba(255, 0, 0, 1);
      font-size: 13px;
    }

    .max {
      margin-top: 0px!important;
      width: fit-content;
      height: 28px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      padding: 0 20px;
      background-color: transparent;
      width: fit-content;
      @media #{$smWidth $xsWidth} {
        display: none;
      }
    }

    &__extra {
      display: flex;
      align-items: center;
      height: 48px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 14px;
      p {
        margin-left: 12px;
        margin-right: 20px;
        width: auto;
        font-size: 16px;
      }

      img {
        width: 24px;
        height: 24px;
        z-index: 0;
        margin-left: 20px;
      }



      &:hover, focus {
        border-color: rgba(255, 255, 255, 0.6);
      }

      &:focus {
        border-color: rgba(255, 255, 255, 0.6);
      }

      .asset {
        display: flex;
        width: 194px;
        height: 36px;
        align-items: center;
        margin-right: 20px;


        &:hover {
          opacity: 0.8;
          border-radius: 14px;
          background-color: rgba(255, 255, 255, 0.08);
          cursor: pointer;
        }
      }

    }
  }

  button {
    width: 100%;
    height: 48px;
    display: flex;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    margin-top: 50px;
    background-color: #000;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    border-radius: 100px;
    line-height: 48px;
    justify-content: center;
    outline: none;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
    padding: 0 24px;

    img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
      margin-bottom: 0;
    }

    &:hover {
      opacity: 0.6;
    }

    &:disabled {
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.3);
      opacity: 1;
    }
  }

  &__step_frame {
    display: flex;
    align-items: center;
    margin: 16px -6px 0 16px ;

    & > * {
      margin: 0 6px;
    }

    .step_circle {
      border: 1px solid #24FF00;
      border-radius: 100px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    svg {

      color: #24FF00;
    }

    div {
      width: 184px;
      height: 1px;
      background-color: #ffffff;
      opacity: 0.6;
    }

    p {
      width: 23px;
      height: 23px;
      border-radius: 11.5px;
      border: 1px solid #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

  }
}

.extra {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  height: 48px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 10px;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  cursor: pointer;
  width: 100%;

  p {
    margin-right: 20px;
  }

  img {
    margin-right: 12px;
    height: 28px;
  }

  .arrow {
    flex: 1;
    height: 12px;
    margin-left: 12px;
  }

  h5 {
    font-size: 16px;
    font-family: Roboto-Regular;
    color: #ffffff;
  }

  @media #{$smWidth $xsWidth} {
    width: initial;
  }


  .amount {
    flex: 1;
    text-align: center;
  }
}

.claim_disabled {
  opacity: 0.6;
  cursor: initial;
}




.claim {
  &__amount {
    font-family: Futura PT;
    font-style: normal;
    font-weight: 500;
    font-size: 28px !important;
    line-height: 148.69%;
    margin-bottom: 24px;
  }
}

.claimed_mode {

  @media #{$smWidth $xsWidth} {
    flex: 0;
    margin-bottom: 0;
    width: -webkit-fill-available;
  }

  > img {
    width: 32px;
    height: 32px;

    @media #{$smWidth $xsWidth} {
      width: 60px;
      height: 60px;
    }
  }

  > p {
    font-size: 18px;
    @media #{$smWidth $xsWidth} {
      font-family: Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 24px !important;
      line-height: 124%;
      width: 259px;
    }
  }

  a {
    margin-top: 32px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 148.69%;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    &:hover {
      opacity: 0.6;
    }
    @media #{$smWidth $xsWidth} {
      font-size: 16px;
      margin-top: 36px;
    }
  }


  .add_token {
    width: 210px;
    height: 36px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    cursor: pointer;
    @media #{$smWidth $xsWidth} {
      width: 266px;
      height: 36px;
      margin-top: 20px;
      p {
        font-size: 16px;
      }
    }

    img {
      width: 15.88px;
      margin-left: 8px;
    }

  }

  button {
    @media #{$smWidth $xsWidth} {
      margin-top: 70px !important;
      height: 60px;
      font-size: 18px;
    }
  }
}


.claim_modal {
  max-height: 469px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);

  nav{
    width: 128px;
    border-radius: 4px;
    max-height: 32px;

    &::after {
      line-height: 32px;
      right: 12px;
      font-size: 12px;
    }

    li > a{
      font-size: 12px;
      line-height: 32px;
      height: 32px;
      padding-left: 12px!important;
    }
  }
  @media #{$smWidth $xsWidth} {
    flex: 0;
    margin-bottom: 0;
    padding: 48px 24px;
    width: -webkit-fill-available;

    .claim__amount {
      margin-bottom: 40px;
      margin-top: 20px;
      font-size: 32px !important;
    }
  }

  button {
    //width: 100% !important;
  }


}


.claim_modal {
  padding-top: 16px;

  .header {

  }

  .claim_list {
    overflow: auto;
    height: 440px;

    .loading_frame {
      display: flex;
      align-items: center;
      height: 100%;
    }


    .empty_list {
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      width: 266px;
    }

  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 28px 0;
  }

  &__footer {
    font-family: Roboto;
    font-size: 14px;
    display: flex;
    width: 398px;
    justify-content: space-between;
    margin-top: 20px;


  }

}



.switch_btn {
  width: 428px !important;
  height: 48px !important;
  margin-top: 16px;

  &:hover {
    opacity: 0.6;
  }

  @media #{$smWidth $xsWidth} {
    width: 100% !important;
  }
}



.claim_item {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  height: 64px;
  padding: 9px 16px;
  display: flex;
  width: 556px;
  margin-bottom: 20px;

  >svg {
    fill: transparent;
    margin: auto 18px;
  }


  >button {
    padding: 6px 14px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
    height: 36px;
    width: 62px;
    margin: auto;
  }

  &__item {
    width: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 12px;



    >p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 148.69%;
      color: rgba(255, 255, 255, 0.6);
      text-align: left;
    }

    >div{
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 5px;
      color: #ffffff;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      p {
        font-size: 14px;
        margin-right: 12px;
      }

      .arrow {
        width: 12px;
      }
    }
  }
}

footer {
  position: fixed;
  bottom: 28px;
  left: 45px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media #{$smWidth $xsWidth} {
    display: none;
  }

  a{
    opacity: 0.8;
  }

  a:hover {
    text-decoration: underline;
    opacity: 1;
  }

  ul{
    display: flex;
    gap: 48px;
    margin-right: 20px;


    li{
      @media #{$smWidth $xsWidth} {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      a {
        svg {
          fill: #7a7f82;
        }
        &:hover svg {
          fill: #ffffff;
        }
      }
    }
  }

}
